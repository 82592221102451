import React from "react";
import { Link } from "react-router-dom";

export default function ProjectCard({ img, title, localLink, liveLink }) {
  const local = `/dev/${localLink}`;
  if (!liveLink) {
    return (
      <Link to={local} className="no-underline">
        <div className="project-card">
          <img
            src={img}
            alt=""
            className="project-card-img"
            width="500"
            height="500"
          />
          <div>{title}</div>
        </div>
      </Link>
    );
  } else {
    return (
      <a
        href={liveLink}
        target="_blank"
        rel="noreferrer"
        className="no-underline"
      >
        <div className="project-card">
          <img
            src={img}
            alt=""
            className="project-card-img"
            width="500"
            height="500"
          />
          <div>{title}</div>
        </div>
      </a>
    );
  }
}
